<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="gfGreenDrageeCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import GreenDpeanut from "../assets/GFGreen/GFGreenDragee/GF_Green_peanut_in_chocolate_front.jpg";
import GreenDpinenut from "../assets/GFGreen/GFGreenDragee/GF_Green_pinenut_in_chocolate_front.jpg";
import GreenDcashew from "../assets/GFGreen/GFGreenDragee/GF_Green_cashew_in_chocolate_front.jpg";
import GreenDalmond from "../assets/GFGreen/GFGreenDragee/GF_Green_almond_in_chocolate_front.jpg";
import GreenDhazelnut from "../assets/GFGreen/GFGreenDragee/GF_Green_huzelnut_in_chocolate_front.jpg";

export default {
  name: "GFGreenDragee",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Драже в шоколаде",
      gfGreenDrageeCards: [
        {
          picture: GreenDpeanut,
          text: "GOOD FOOD Green Арахис в шоколаде 150 г",
          id: 1,
          description:
            "Состав: шоколадная масса (сахар, масло какао, тёртое какао, какао-порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), жареные ядра арахиса.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки - 14,1 г, Жиры 40,8 г, Углеводы 33,8 г.<br>Энергетическая ценность 558 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenDpinenut,
          text: "GOOD FOOD Green Кедровый орех в шоколаде 150 г",
          id: 2,
          description:
            "Состав: шоколадная масса (cахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), ядра кедрового ореха.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки - 7,3 г, Жиры 35,0 г, Углеводы 45,1 г.<br>Энергетическая ценность 524 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenDcashew,
          text: "GOOD FOOD Green Кешью в шоколаде 150 г",
          id: 3,
          description:
            "Состав: шоколадная масса (cахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили), жареные ядра кешью.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки - 12,4 г, Жиры 42,4 г, Углеводы 36,4 г.<br>Энергетическая ценность 577 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenDalmond,
          text: "GOOD FOOD Green Миндаль в шоколаде 150 г",
          id: 4,
          description:
            "Состав: шоколадная масса (cахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)), жареные ядра миндаля.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки - 15,8 г, Жиры 40,7 г, Углеводы 34,2 г.<br>Энергетическая ценность 566 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenDhazelnut,
          text: "GOOD FOOD Green Фундук в шоколаде 150 г",
          id: 5,
          description:
            "Состав: шоколадная масса (cахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили), жареные ядра фундука.<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки - 12,4 г, Жиры 44,3 г, Углеводы 35,1 г.<br>Энергетическая ценность 525 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9122-003-60458573-09<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>